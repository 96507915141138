import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
  Form,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useEditCategoryMutation,
  useGetCitiesQuery,
  useGetFullCategoryDetailsQuery,
  useGetGovernmentsQuery,
} from "../../../redux/Service/api";

const EditCategory = ({
  setOpenEditModal,
  openEditModal,
  categoryIdForEdit,
}: any) => {
  const [form] = Form.useForm();
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null); // Track uploaded file

  // Fetch category details
  const {
    data: allCategoryData,
    isError: isAllCategoryError,
    isLoading: isAllCategoryLoading,
  } = useGetFullCategoryDetailsQuery(categoryIdForEdit);

  const allCategoryDetails = allCategoryData?.data ?? {};

  const { data: allCitiesData } = useGetCitiesQuery(undefined);
  const { data: allGovernmentsData } = useGetGovernmentsQuery(undefined);

  const allCities = allCitiesData?.data?.data ?? [];
  const allGovernments = allGovernmentsData?.data?.data ?? [];
  console.log(allCategoryDetails);

  useEffect(() => {
    if (allCategoryDetails) {
      form.setFieldsValue({
        nameAR: allCategoryDetails?.name,
        nameEN: allCategoryDetails?.name_en,
        government_id: allCategoryDetails?.government_id,
        city_id: allCategoryDetails?.cities?.map((city: any) => city.id) || [],
      });
      setFile(allCategoryDetails?.image);
    }
  }, [allCategoryDetails, form]);

  const [editCategory, { isLoading: isEditLoading }] =
    useEditCategoryMutation();

  const handleFileChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleEditOffer = async (values: any) => {
    const { nameAR, nameEN, government_id, city_id } = values;

    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    formData.append("government_id", government_id);
    city_id.forEach((cityId: string) => formData.append("cities[]", cityId));

    // Append the file only if it's a new upload
    if (file) {
      formData.append("photo", file);
    }

    try {
      const res = await editCategory({
        id: categoryIdForEdit,
        formData,
      }).unwrap();
      setFile(null);
      form.resetFields();
      setModalKey((prev) => prev + 1);

      message.success(res.message || "Category updated successfully.");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error editing Category:", error);
      message.error(
        "An error occurred while editing the category. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Category"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer={null}
    >
      {isAllCategoryLoading ? (
        <Spin size="small" style={{ marginRight: "0.2rem" }} />
      ) : isAllCategoryError ? (
        <p className="text-red-500">Something went wrong</p>
      ) : (
        <Form form={form} layout="vertical" onFinish={handleEditOffer}>
          <Space direction="vertical" className="w-full" size="middle">
            <Form.Item
              name="nameAR"
              label="Name in Arabic"
              rules={[
                { required: true, message: "Please enter name in Arabic" },
              ]}
            >
              <Input placeholder="Name in Arabic" />
            </Form.Item>

            <Form.Item
              name="nameEN"
              label="Name in English"
              rules={[
                { required: true, message: "Please enter name in English" },
              ]}
            >
              <Input placeholder="Name in English" />
            </Form.Item>

            <Form.Item
              label="المنطقه"
              name="government_id"
              rules={[
                { required: true, message: "Please select a government" },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Government"
                optionFilterProp="label"
                options={allGovernments?.map((gov: any) => ({
                  value: gov.id,
                  label: gov.name,
                }))}
              />
            </Form.Item>

            <Form.Item
              label="المدينه"
              name="city_id"
              rules={[
                { required: true, message: "Please select at least one city" },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Cities"
                optionFilterProp="label"
                options={allCities?.map((city: any) => ({
                  value: city.id,
                  label: city.name,
                }))}
              />
            </Form.Item>

            {file && (
              <Image
                src={
                  typeof file === "string" ? file : URL.createObjectURL(file)
                } // Use URL.createObjectURL for new files
                height={150}
                width={150}
                fallback="https://via.placeholder.com/150"
                alt="Category"
              />
            )}

            <Form.Item label="Upload Image" required>
              <Upload
                customRequest={({ onSuccess }) => onSuccess?.(undefined)}
                onChange={handleFileChange}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Change Image (Max: 1)</Button>
              </Upload>
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              loading={isEditLoading}
              className="w-full"
            >
              Edit
            </Button>
          </Space>
        </Form>
      )}
    </Modal>
  );
};

export default EditCategory;
