import React from 'react'
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'; // Example icon

const MyProfileName = () => {
	return (
		<Link to={"/profile/addNameProfile"} className='flex items-center justify-between'>
			<div className='flex items-center gap-[30px]'>
				<RightOutlined className='text-[#a8a8a8] text-[14px]' />
				<p className='text-[14px] text-[#696969]'>Saddam Shadab</p>
			</div>
			<p className='text-[14px] font-bold'>
				الاسم
			</p>
		</Link>
	)
}

export default MyProfileName