import { Button, Drawer, Input } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';


const AddNameProfile = () => {
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};



	const onClose = () => {
		setOpen(false);
	};
	return (
		<>
			<div className='bg-[#ffff] mb-3 pt-7 pb-7'>
				<div className='container flex justify-between'>
					<div className='w-[80%]'>
						<Button onClick={showDrawer} className='bg-[#282828] text-[#ffff] pr-5 pl-5text-[12px]'>
							حفظ
						</Button>
						<Drawer
							// title="Drawer with extra actions"
							placement={"bottom"}
							width={500}
							onClose={onClose}
							closeIcon={false}
							open={open}
							extra={
								<></>
							}
						>
							<div className='flex flex-col gap-[30px] items-center'>
								<div className='bg-[#3FAD46] w-[80px] rounded-[50%] h-[80px] flex justify-center items-center'>
									<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.2" d="M22.5 5.625C19.0864 5.62503 15.7531 6.6603 12.9402 8.59414C10.1272 10.528 7.96685 13.2694 6.74433 16.4566C5.52181 19.6437 5.29461 23.1267 6.09273 26.4457C6.89084 29.7646 8.67674 32.7635 11.2146 35.0464V35.0462C12.2734 32.9632 13.8883 31.2139 15.8802 29.9923C17.8721 28.7707 20.1633 28.1244 22.5 28.125C21.1094 28.125 19.7499 27.7126 18.5937 26.94C17.4374 26.1674 16.5362 25.0693 16.004 23.7845C15.4718 22.4997 15.3326 21.0859 15.6039 19.722C15.8752 18.3581 16.5448 17.1052 17.5282 16.1219C18.5115 15.1386 19.7643 14.4689 21.1283 14.1976C22.4922 13.9263 23.906 14.0655 25.1907 14.5977C26.4755 15.1299 27.5737 16.0311 28.3463 17.1874C29.1189 18.3437 29.5313 19.7031 29.5313 21.0938C29.5312 22.9586 28.7905 24.747 27.4718 26.0656C26.1532 27.3842 24.3648 28.125 22.5 28.125C24.8367 28.1244 27.1279 28.7707 29.1198 29.9923C31.1118 31.2139 32.7266 32.9632 33.7854 35.0462C36.3233 32.7633 38.1092 29.7645 38.9073 26.4455C39.7054 23.1266 39.4782 19.6436 38.2557 16.4565C37.0331 13.2693 34.8728 10.5279 32.0598 8.59407C29.2469 6.66026 25.9136 5.62501 22.5 5.625Z" fill="#282828" />
										<path d="M22.5 39.375C31.8198 39.375 39.375 31.8198 39.375 22.5C39.375 13.1802 31.8198 5.625 22.5 5.625C13.1802 5.625 5.625 13.1802 5.625 22.5C5.625 31.8198 13.1802 39.375 22.5 39.375Z" stroke="white" stroke-width="2.5" stroke-miterlimit="10" />
										<path d="M22.5 28.125C26.3833 28.125 29.5312 24.977 29.5312 21.0938C29.5312 17.2105 26.3833 14.0625 22.5 14.0625C18.6167 14.0625 15.4688 17.2105 15.4688 21.0938C15.4688 24.977 18.6167 28.125 22.5 28.125Z" stroke="white" stroke-width="2.5" stroke-miterlimit="10" />
										<path d="M11.2148 35.0462C12.2741 32.9636 13.889 31.2147 15.8808 29.9931C17.8726 28.7716 20.1636 28.125 22.5001 28.125C24.8367 28.125 27.1277 28.7716 29.1195 29.9931C31.1113 31.2147 32.7262 32.9635 33.7855 35.0462" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>


								</div>
								<h2 className=' font-bold text-[20px] miilg:text-[20px] mid:text-[18px]'>
								تم تعديل الاسم بنجاح						
										</h2>

								<Button onClick={onClose} className='bg-[#282828] text-[#ffff] pb-3 pt-3 text-[12px] font-bold w-[20%]'>
									ok
								</Button>
							</div>
						</Drawer>
					</div>
					<div className='flex justify-center gap-[13px] items-center w-[150px] mid:w-[200px]'>
						<h2 className=' font-bold text-[20px]  miilg:text-[20px] mid:text-[18px]'>
							تعديل الاسم
						</h2>
						<Link to={'/profile'} className='flex mt-[5px] items-center justify-center h-fit'>
							<LeftOutlined
							/>
						</Link>
					</div>
				</div>

			</div>
			<div className='container'>
				<Input placeholder="Basic usage" value={"Saddam Shadab"} className='h-[40px]' />

			</div>
		</>
	)
}

export default AddNameProfile