import { Link } from "react-router-dom";
import C1 from "../../assets/C1.svg";
import C2 from "../../assets/C2.svg";
import C3 from "../../assets/C3.svg";

function Hejozati() {
  return (
    <div className="container mt-5 md:mt-10">
      <div className="grid grid-cols-5 justify-between items-center gap-5 border-[3px] px-5 py-10 rounded-b-3xl shadow-lg bg-[#F3EDF7]">
        <div className="col-span-2 grid justify-center items-center gap-2">
          <img src={C1} className="w-full md:w-[80%] mx-auto" alt="c1" />

          <Link
            to={'/categories'}
            className="w-[80%] text-center md:w-[60%] text-white md:font-semibold bg-gray-800 hover:bg-gray-900  rounded-b-lg text-sm md:text-xl md:px-5 md:py-1 mx-auto dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          >
            احجز حالا
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <img src={C2} className="w-full md:w-[80%]" alt="c2" />
        </div>
        <div className="col-span-2 grid justify-center items-center gap-2">
          <img src={C3} className="w-full md:w-[80%] mx-auto" alt="c1" />
          <Link
            to={"/worker"}
            className="w-[80%] text-center md:w-[60%] text-white md:font-semibold bg-gray-800 hover:bg-gray-900  rounded-b-lg text-sm md:text-xl md:px-5 md:py-1 mx-auto dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          >
            إنضم الينا
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hejozati;
