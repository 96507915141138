import Heading from "../../../Components/Heading/Heading";
import TableAccountStatement from "./TableAccountStatement";

function AccountStatement() {
  return (
    <>
      <Heading heading="كشف حساب" />
      <TableAccountStatement />
    </>
  );
}

export default AccountStatement;
