import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import Avater from '../../assets/Png/Avater.png'
import iconImage from '../../assets/Png/Iconsimage.png'
import iconcamera from '../../assets/Png/Iconscamera.png'
import { Button, Upload, Form, Space, Drawer, DrawerProps, RadioChangeEvent } from 'antd';
import './addImageProfile.scss'
import { useState } from 'react';
const AddImageProfile = () => {
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

	const showDrawer = () => {
		setOpen(true);
	};



	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className='bg-[#ffff] mb-3 pt-7 pb-7'>
				<div className='container flex justify-between'>
					<div className='w-[80%]'>
						<Button onClick={showDrawer} className='bg-[#282828] text-[#ffff] pr-5 pl-5text-[12px]'>
							حفظ
						</Button>
						<Drawer
							// title="Drawer with extra actions"
							placement={"bottom"}
							width={500}
							onClose={onClose}
							closeIcon={false}
							open={open}
							extra={
								<></>
							}
						>
							<div className='flex flex-col gap-[30px] items-center'>
								<div className='bg-[#3FAD46] w-[80px] rounded-[50%] h-[80px] flex justify-center items-center'>
									<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.2" d="M36.5625 7.03125H8.4375C8.06454 7.03125 7.70685 7.17941 7.44313 7.44313C7.17941 7.70685 7.03125 8.06454 7.03125 8.4375V36.5625C7.03125 36.9355 7.17941 37.2931 7.44313 37.5569C7.70685 37.8206 8.06454 37.9688 8.4375 37.9688H36.5625C36.9355 37.9688 37.2931 37.8206 37.5569 37.5569C37.8206 37.2931 37.9688 36.9355 37.9688 36.5625V8.4375C37.9688 8.06454 37.8206 7.70685 37.5569 7.44313C37.2931 7.17941 36.9355 7.03125 36.5625 7.03125ZM10.1574 37.9687C10.7956 35.1725 12.3643 32.6759 14.6066 30.8876C16.8489 29.0992 19.6319 28.1252 22.5 28.125C21.1094 28.125 19.7499 27.7126 18.5936 26.94C17.4374 26.1674 16.5361 25.0693 16.004 23.7845C15.4718 22.4997 15.3326 21.0859 15.6039 19.722C15.8752 18.3581 16.5448 17.1052 17.5282 16.1219C18.5115 15.1386 19.7643 14.4689 21.1283 14.1976C22.4922 13.9263 23.906 14.0655 25.1907 14.5977C26.4755 15.1299 27.5737 16.0311 28.3463 17.1874C29.1189 18.3437 29.5312 19.7031 29.5312 21.0938C29.5312 22.9586 28.7905 24.747 27.4718 26.0656C26.1532 27.3842 24.3648 28.125 22.5 28.125C25.3681 28.1252 28.1511 29.0992 30.3934 30.8876C32.6357 32.6759 34.2044 35.1725 34.8426 37.9687H10.1574Z" fill="#282828" />
										<path d="M22.5 28.125C26.3833 28.125 29.5312 24.977 29.5312 21.0938C29.5312 17.2105 26.3833 14.0625 22.5 14.0625C18.6167 14.0625 15.4688 17.2105 15.4688 21.0938C15.4688 24.977 18.6167 28.125 22.5 28.125Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M36.5625 7.03125H8.4375C7.66085 7.03125 7.03125 7.66085 7.03125 8.4375V36.5625C7.03125 37.3392 7.66085 37.9688 8.4375 37.9688H36.5625C37.3392 37.9688 37.9688 37.3392 37.9688 36.5625V8.4375C37.9688 7.66085 37.3392 7.03125 36.5625 7.03125Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10.1569 37.9687C10.7949 35.1724 12.3635 32.6756 14.6058 30.8873C16.8482 29.0989 19.6313 28.125 22.4995 28.125C25.3676 28.125 28.1508 29.0989 30.3931 30.8873C32.6355 32.6756 34.2041 35.1724 34.8421 37.9687" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>

								</div>
								<h2 className=' font-bold text-[20px] miilg:text-[20px] mid:text-[18px]'>
									تم اختيار الصوره					
										</h2>

								<Button onClick={onClose} className='bg-[#282828] text-[#ffff] pb-3 pt-3 text-[12px] font-bold w-[20%]'>
									ok
								</Button>
							</div>
						</Drawer>
					</div>
					<div className='flex justify-center gap-[13px] items-center w-[150px] mid:w-[200px]'>
						<h2 className=' font-bold text-[20px]  miilg:text-[20px] mid:text-[18px]'>
							اضافه صوره
						</h2>
						<Link to={'/profile'} className='flex mt-[5px] items-center justify-center h-fit'>
							<LeftOutlined />
						</Link>
					</div>
				</div>
			</div>
			<div className='flex flex-col items-center pt-20 AddImageProfile'>
				<div>
					<img src={Avater} alt="" />
				</div>
				<div className='mb-5 mt-5 w-[300px]'	>
					<Form.Item
						name="upload"
						label=""
						valuePropName="fileList"
						className='mb-0'
					// getValueFromEvent={normFile}
					// extra="longgggggggggggggggggggggggggggggggggg"
					>
						<Upload name="logo" action="/upload.do" listType="picture">
							<Button className='bg-[#E2E8FF]'>
								<span>
									الاختيار من المعرض
								</span>
								<img src={iconImage} className=' h-full	' alt="" />
							</Button>
						</Upload>
					</Form.Item>
				</div>
				<div className='w-[300px]'>
					<Form.Item
						name="upload"
						label=""
						className='mb-0'
						valuePropName="fileList"
					// getValueFromEvent={normFile}
					// extra="longgggggggggggggggggggggggggggggggggg"
					>
						<Upload name="logo" action="/upload.do" listType="picture">
							<Button className='bg-[#E2E8FF] w-full'>
								<span>
									الكاميرا
								</span>
								<img src={iconcamera} className=' h-full	' alt="" />
							</Button>
						</Upload>
					</Form.Item>
				</div>
			</div>
		</>
	)
}

export default AddImageProfile