import { RightOutlined, StarFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import type { FormInstance } from 'antd';
import { Button, Form, Input, Select, Space } from 'antd';

import React, { useEffect } from 'react';
 import './WorkerLogin.scss'
import WorkerLoginDrawer from './WorkerLoginDrawer';


const { Option } = Select;

interface SubmitButtonProps {
	form: FormInstance;
}
const WorkerLogin = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();


	const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
		const [submittable, setSubmittable] = React.useState<boolean>(false);

		// Watch all values
		const values = Form.useWatch([], form);
const navigate = useNavigate()
		useEffect(() => {
			form
				.validateFields({ validateOnly: true })
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);

		return (
			<Button type="primary" className='bg-[#090357] w-[200px] mt-10' htmlType="submit" 
			// onClick={()=> navigate('/workerhome')}
			// disabled={!submittable}
			>
				{children}
			</Button>
		);
	};





	return (
		<div className=' container WorkerLogin pt-12 pb-12'>
			<h2 className='flex mb-10 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>
				<RightOutlined className='text-[18px] cursor-pointer' onClick={() => navigate("/worker")} />
				<span >
					سعداء بوجودك
				</span>
			</h2>
			<div>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<Form.Item name="fristname" label="الاسم الاول" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="phone" label="رقم الموبايل" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="email" label="البريد الالكترونى" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="servicesType"
						label="نوع الخدمه"
						rules={[{ required: true, message: 'الرجاء اختيار نوع الخدمة' }]}
					>
						<Select placeholder="select your gender">
							<Option value="male">Male</Option>
							<Option value="female">Female</Option>
							<Option value="other">Other</Option>
						</Select>
					</Form.Item>
					<Form.Item name="years" label="سنوات الخبرة" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="country"
						label="المنطقه / المدينه"
						rules={[{ required: true, message: 'الرجاء اختيار نوع الخدمة' }]}
					>
						<Select placeholder="select your gender">
							<Option value="male">Male</Option>
							<Option value="female">Female</Option>
							<Option value="other">Other</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="party"
						label="المناسبات"
						rules={[{ required: true, message: 'الرجاء اختيار نوع الخدمة' }]}
					>
						<Select placeholder="اختار من القائمه ">
							<Option value="male">حفلات ومناسبات</Option>
							<Option value="female">اجتماعات الشله</Option>
							<Option value="other">كشتة بريه</Option>
							<Option value="other">خدمات الضيافه</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="servicesCountry"
						label="المدينة"
						rules={[{ required: true, message: 'الرجاء اختيار نوع الخدمة' }]}
					>
						<Select placeholder="اختار المدينه المراد تقديم الخدمه بها ">
							<Option value="male">الرياض</Option>
							<Option value="female">جده</Option>
							<Option value="other">حائل</Option>
							<Option value="other">القصيم</Option>
						</Select>
					</Form.Item>

					<Form.Item>
						<Space>
							<SubmitButton form={form}>
								
								
								<WorkerLoginDrawer/>
							</SubmitButton>
						</Space>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

export default WorkerLogin