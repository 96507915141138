import React, { useState } from "react";
import { Modal, message, Button, Input, Select } from "antd";
import { useCreateNewUserMutation } from "../../../redux/Service/api";

const { Option } = Select;

const AddUser = () => {
  const [modalKey, setModalKey] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [role, setRole] = useState("admin"); // Default role

  const [addUser, { isLoading }] = useCreateNewUserMutation();

  const handleAddUser = async () => {
    if (!name) {
      message.error("يرجى إدخال الاسم");
      return;
    }
    if (!email) {
      message.error("يرجى إدخال البريد الإلكتروني");
      return;
    }
    if (!password) {
      message.error("يرجى إدخال كلمة المرور");
      return;
    }
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      setPasswordError(
        "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل وتحتوي على حروف صغيرة وكبيرة"
      );
      return;
    }

    const requestBody = {
      name,
      email,
      password,
      role, // Include selected role
    };

    try {
      const res = await addUser(requestBody).unwrap();

      setName("");
      setEmail("");
      setPassword("");
      setPasswordError("");
      setRole("admin"); // Reset role to default

      setModalKey((prev) => prev + 1);
      message.success(res.message || "تم إضافة المستخدم بنجاح");
      setOpenAddModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة المستخدم:", error);
      message.error("حدث خطأ أثناء إضافة المستخدم. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenAddModal(true)}
        >
          إضافة
        </Button>
      </div>
      <Modal
        key={modalKey}
        title="إنشاء مستخدم"
        open={openAddModal}
        onCancel={() => setOpenAddModal(false)}
        width={500}
        footer={null}
        style={{ direction: "rtl" }} // Set text direction to RTL for Arabic
      >
        <div className="space-y-6">
          <div className="space-y-4">
            <Input
              size="large"
              placeholder="الاسم"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              size="large"
              placeholder="البريد الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              size="large"
              placeholder="كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            {passwordError && <p className="text-red-500">{passwordError}</p>}
            <Select
              size="large"
              style={{ width: "10rem" }}
              value={role}
              onChange={setRole}
              placeholder="اختر الدور"
            >
              <Option value="admin">مدير</Option>
              <Option value="user">مستخدم</Option>
              <Option value="provider">مزود</Option>
            </Select>
          </div>

          <div className="flex justify-end">
            <Button type="primary" onClick={handleAddUser} loading={isLoading}>
              إنشاء
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUser;
