import React, { useState } from "react";
import LatestNews1 from "../../../assets/LatestNews1.svg";
import LatestNews2 from "../../../assets/LatestNews2.svg";
import { Button, Input, message, Modal, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
function NewsSettings() {
  const [openSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddProduct = async () => {
    if (!file) {
      message.error("يرجى تحميل ملف");
      return;
    }
    if (!title) {
      message.error("الرجاء إدخال عنوان");
      return;
    }

    // المعالجة المفترضة لرفع الملف
    try {
      // قم برفع الملف هنا
      setFile(null);
      setTitle("");
      setModalKey((prev) => prev + 1);
      message.success("تمت إضافة شريط التمرير بنجاح");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة شريط التمرير:", error);
      message.error(
        "حدث خطأ أثناء إضافة شريط التمرير. يرجى المحاولة مرة أخرى."
      );
    }
  };

  return (
    <div className="container">
      <div className="flex justify-center ">
        <Button
          onClick={() => setOpenSliderModal(true)}
          type="primary"
          className="md:w-28"
        >
          اضافه
        </Button>
      </div>
      <div className="mt-7 md:mt-14">
        <h1 className="text-xl md:text-3xl font-bold mb-4">اخر الاخبار </h1>
        <div className="grid grid-cols-1 gap-5 md:gap-10">
          <div
            className="h-[200px] md:h-[300px] rounded-tl-3xl relative"
            style={{
              backgroundImage: `url(${LatestNews1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex justify-center mt-5 gap-3">
              <Button type="primary" className="md:w-28">
                تعديل
              </Button>
              <Button type="primary" className="md:w-28" danger>
                حذف
              </Button>
            </div>
            <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
              السعودية تلغي رسوم تراخيص الفنادق في مسعى لدعم السياحة
            </h1>
          </div>
          <div
            className="h-[200px] md:h-[300px] rounded-tl-3xl relative"
            style={{
              backgroundImage: `url(${LatestNews2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex justify-center mt-5 gap-3">
              <Button type="primary" className="md:w-28">
                تعديل
              </Button>
              <Button type="primary" className="md:w-28" danger>
                حذف
              </Button>
            </div>
            <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
              وزارة السياحة: إنفاق زوار المملكة يتخطى 45 مليار ريال في الربع
              الأول من 2024
            </h1>
          </div>
        </div>
      </div>
      {/* إضافة اخبار */}
      <Modal
        title="إضافة"
        centered
        open={openSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        width={1000}
        footer={null}
        key={modalKey}
      >
        <div className="flex flex-wrap justify-between gap-5">
          <div className="flex flex-col w-full">
            <Input
              size="large"
              placeholder="عنوان"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mb-4"
            />
          </div>
          <div className="w-full">
            <Upload
              customRequest={({ file, onSuccess }) => {
                onSuccess?.("ok");
              }}
              onChange={({ fileList }) => handleFileChange(fileList)}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<FontAwesomeIcon icon={faDownload} />}>
                تحميل (الحد الأقصى: 1)
              </Button>
            </Upload>
          </div>
          <div className="mt-4">
            <Button type="primary" onClick={handleAddProduct} loading={false}>
              إضافة
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NewsSettings;
