// src/store.ts
import { configureStore } from "@reduxjs/toolkit";
import { api } from "./Service/api";
import authReducer from "./Slices/authSlice";
// import DarkModeReducer from "./Slices/DarkModeSlice";

// Configure the store
const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer, // API reducer
    auth: authReducer,              // Auth reducer
    // DarkMode: DarkModeReducer,     // DarkMode reducer (if you have one)
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware), // Adding API middleware
});

// Export the RootState type based on the store
export type RootState = ReturnType<typeof store.getState>; // Correct usage of RootState type

export default store;
