import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MyProfileImage from './MyProfileImage';
import MyProfileName from './MyProfileName';
import MyProfileEmail from './MyProfileEmail';
import MyProfilePhone from './MyProfilePhone';
import MyProfileGender from './MyProfileGender';
import MyProfileServices from './MyProfileServices';

const MyProfile = () => {
	return (
		<>
			<div className='bg-[#ffff] mb-3 pt-7 pb-7'>
				<div className='container'>
					<div className='flex justify-between w-full items-center'>
						<h2 className='flex items-center flex-col gap-2 font-bold text-[20px] miilg:text-[20px] mid:text-[18px]'>
							ملفى الشخصى
						</h2>
						<Link to={'/'}>
							<LeftOutlined />
						</Link>
					</div>
				</div>
			</div>
			<div className='bg-[#ffff] pt-5 pb-5 mb-[2px]'>
				<div className=' container'>
					<MyProfileImage />
				</div>

			</div>
			<div className='bg-[#ffff] pt-5 pb-5 mb-[2px]'>
				<div className=' container  '>
					<MyProfileName />
				</div>
			</div>
			<div className='bg-[#ffff] pt-5 pb-5 mb-[2px]'>
				<div className=' container  '>
					<MyProfileEmail />
				</div>
			</div>
			<div className='bg-[#ffff] pt-5 pb-5 mb-[2px]'>
				<div className=' container  '>
					<MyProfilePhone />
				</div>
			</div>
			<div className='bg-[#ffff] pt-5 pb-5 mb-[2px]'>
				<div className=' container  '>
					<MyProfileGender />
				</div>
			</div>
			<div className='bg-[#ffff] pt-5 pb-5'>
				<div className=' container  '>
					<MyProfileServices />
				</div>
			</div>

		</>
	)
}

export default MyProfile