import { RightOutlined } from '@ant-design/icons'; // Example icon
import Avater from '../../assets/Png/Avater.png'
import { Link } from 'react-router-dom';
const MyProfileImage = () => {
	return (
		<Link to={"/profile/addimage"} className='flex items-center justify-between mb-1'>
			<div className='flex items-center gap-[30px]'>
			<RightOutlined className='text-[#a8a8a8] text-[14px]' />
			<div className='w-[70px] h-[70px]'>
					<img src={Avater} className='w-full h-full' alt="" />
				</div>
			</div>
			<p className='text-[14px] font-bold'>
				صورة
			</p>
		</Link>
	)
}

export default MyProfileImage