import { RightOutlined, StarFilled } from '@ant-design/icons';
import companyCatoger from '../../assets/Png/companycatogery1.jpeg'
import { useNavigate } from 'react-router';

const CompanyCatogery = () => {
	const navigate = useNavigate();
	return (
		<div className="container pb-12 pt-5">
			<h2 className='flex mb-5 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>
				<RightOutlined className='text-[18px] cursor-pointer' onClick={() => navigate("/placeHolder")} />
				<span >
					الشركات المتاحة
				</span>
			</h2>
			<div className="flex justify-start gap-[10px] flex-wrap items-center">
				<div
					onClick={() => navigate("/OrderDetails")}
					className="flex justify-between flex-nowrap rounded-[8px] items-center bg-[#E2E8FF] pt-3 pb-3 cursor-pointer pl-2 pr-2 mid:min-w-[100%] min-w-[32%]">
					<img src={companyCatoger} className='ml-2 w-[80px] h-[80px] rounded-[50%]' alt="" />
					<div className='flex flex-col gap-[20px] w-[65%] mid:w-[80%] '>
						<h3 className='flex items-center justify-between text-[14px]'>
							<span>
								شركه الدار
							</span>
							<span className='text-[10px] font-bold bg-[#6885EA] p-1 text-[#fff]'>
								افضل
								سعر
							</span>
						</h3>
						<div className='flex flex-col gap-[7px]'>
							<p>1,149.00 ريال</p>
							<p>1,149.00 ريال
								799.00 ريال</p>
							<p className='flex gap-1'>
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
							</p>
						</div>
					</div>


				</div>
				<div
					onClick={() => navigate("/OrderDetails")}

					className="flex justify-between flex-nowrap mid:min-w-[100%] rounded-[8px] items-center bg-[#E2E8FF] pt-3 pb-3 cursor-pointer pl-2 pr-2 min-w-[32%]">
					<img src={companyCatoger} className='ml-2 w-[80px] h-[80px] rounded-[50%]' alt="" />
					<div className='flex flex-col gap-[20px] w-[65%] mid:w-[80%]'>
						<h3 className='flex items-center justify-between text-[14px]'>
							مؤسسه الغريب العجيب
						</h3>
						<div className='flex flex-col gap-[7px]'>
							<p>1,149.00 ريال</p>
							<p>1,149.00 ريال
								799.00 ريال</p>
							<p className='flex gap-1'>
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
							</p>
						</div>
					</div>


				</div>
				<div
					onClick={() => navigate("/OrderDetails")}

					className="flex justify-between mid:min-w-[100%] flex-nowrap rounded-[8px] items-center bg-[#E2E8FF] pt-3 pb-3 cursor-pointer pl-2 pr-2 min-w-[32%]">
					<img src={companyCatoger} className='ml-2 w-[80px] h-[80px] rounded-[50%]' alt="" />
					<div className='flex flex-col gap-[20px] w-[65%] mid:w-[80%]'>
						<h3>شركه الدار </h3>
						<div className='flex flex-col gap-[7px]'>
							<p>1,149.00 ريال</p>
							<p>1,149.00 ريال
								799.00 ريال</p>
							<p className='flex gap-1'>
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
							</p>
						</div>
					</div>


				</div>
				<div
					onClick={() => navigate("/OrderDetails")}

					className="flex justify-between mid:min-w-[100%] flex-nowrap rounded-[8px] items-center bg-[#E2E8FF] pt-3 pb-3 cursor-pointer pl-2 pr-2 min-w-[32%]">
					<img src={companyCatoger} className='ml-2 w-[80px] h-[80px] rounded-[50%]' alt="" />
					<div className='flex flex-col gap-[20px] w-[65%] mid:w-[80%]'>
						<h3>شركه الدار </h3>
						<div className='flex flex-col gap-[7px]'>
							<p>1,149.00 ريال</p>
							<p>1,149.00 ريال
								799.00 ريال</p>
							<p className='flex gap-1'>
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
								<StarFilled className='text-orange-500 text-[16px]' />
							</p>
						</div>
					</div>


				</div>
			</div>
		</div>
	)
}

export default CompanyCatogery