import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import TextArea from "antd/es/input/TextArea";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

function AddComplaintsAndSuggestions() {
  // form
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      {/* start data entry  */}
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 gap-5 mt-10"
      >
        <Form.Item label="كود الشكوى">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label=" اسم الجهه ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="رقم الموبايل">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="نص الشكوى" className="col-span-2">
          <TextArea className="w-full" rows={2} />
        </Form.Item>
        <Form.Item label="الحل المقترح" className="col-span-2">
          <TextArea className="w-full" rows={2} />
        </Form.Item>
      </Form>
      {/* end */}
      <div className="mt-3">
        <div className="flex justify-end items-center mt-5 gap-10">
          <Button
            className="h-10 w-[200px] flex items-center font-bold justify-center"
            type="primary"
          >
            حفظ
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddComplaintsAndSuggestions;
