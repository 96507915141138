import React from 'react'
import { Outlet } from 'react-router'
import Navbar from './Navbar'
import Footer from './Footer'

const ProfileLayout = () => {
	return (
		<div className='bg-[#eaeefd] min-h-[100vh]'>
			<Navbar />
			<div className='bg-[#eaeefd] pb-[80px]'>
				<Outlet />
			</div>
		</div>
	)
}

export default ProfileLayout