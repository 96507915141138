import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://service.prosofteg.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem("hejozatiToken");
    const language = localStorage.getItem("language");

    headers.set("Accept-Language", language ? language : "ar");
    headers.set("Accept", "application/json");

    if (token && endpoint !== "login" && endpoint !== "register") {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    // ********* Auth & User Management ********* //
    register: builder.mutation({
      query: (userData) => ({
        url: "api/auth/register",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/auth/login",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["User"],
    }),
    updateUserData: builder.mutation({
      query: (userData) => ({
        url: "api/auth/update",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    createNewUser: builder.mutation({
      query: (userData) => ({
        url: "api/admin/store",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    adminGetAllUser: builder.query({
      query: () => "api/admin",
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    // ********* ServicesTypes ********* //
    getAllCategories: builder.query({
      query: () => "api/categories",
      providesTags: ["categories", "services"],
    }),
    getCategoryDetails: builder.query({
      query: (catId) => `api/categories/${catId}`,
      providesTags: ["categories", "services"],
    }),
    addCategory: builder.mutation({
      query: (formData) => ({
        url: "api/admin/categories",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "services"],
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `api/admin/categories/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "services"],
    }),
    getFullCategoryDetails: builder.query({
      query: (id) => `api/admin/categories/${id}/edit`,
      providesTags: ["categories", "services"],
    }),
    editCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/categories/update/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "services"],
    }),

    // ********* Services ********* //
    getAllServices: builder.query({
      query: () => "api/services",
      providesTags: ["services"],
    }),
    getServiceDetails: builder.query({
      query: (serviceId) => `api/services/${serviceId}`,
      providesTags: ["services"],
    }),
    addService: builder.mutation({
      query: (formData) => ({
        url: "api/users/services",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),
    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: `api/users/services/${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["services"],
    }),
    getFullServiceDetails: builder.query({
      query: (id) => `api/services/${id}/edit`,
      providesTags: ["services"],
    }),
    editService: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/users/services/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),

    // ********* Products ********* //
    getAllProducts: builder.query({
      query: () => "api/items?query=all",
      providesTags: ["products"],
    }),
    getAllProductsByCategory: builder.query({
      query: (catId) => `api/items?category_id=${catId}`,
      providesTags: ["products"],
    }),
    getAllProductsPerPage: builder.query({
      query: (pageNumber) => `api/items?page=${pageNumber}`,
      providesTags: ["products"],
    }),
    getOneProduct: builder.query({
      query: (id) => `api/items/${id}`,
      providesTags: ["products"],
    }),
    getAllSpecialProducts: builder.query({
      query: () => "api/items?is_special=1",
      providesTags: ["products"],
    }),
    addProduct: builder.mutation({
      query: (formData) => ({
        url: "api/admin/items",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    deleteProduct: builder.mutation({
      query: (prodId) => ({
        url: `api/admin/items/${prodId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getFullProductDetails: builder.query({
      query: (id) => `api/items/${id}/edit`,
      providesTags: ["categories", "products"],
    }),
    editProduct: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/items/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    deleteProdPhoto: builder.mutation({
      query: (photoId) => ({
        url: `api/admin/photos/${photoId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    addProdPhoto: builder.mutation({
      query: ({ id, newPhotosFormData }) => ({
        url: `api/admin/item/photos/${id}`,
        method: "POST",
        body: newPhotosFormData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    makeItemSpecialOrNot: builder.mutation({
      query: (prodId) => ({
        url: `api/admin/items/special/${prodId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),

    // Cities
    getCities: builder.query({
      query: () => "api/cities",
      providesTags: ["cities"],
    }),
    addCity: builder.mutation({
      query: (formData) => ({
        url: "api/admin/cities",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["cities"],
    }),
    deleteCity: builder.mutation({
      query: (cityId) => ({
        url: `api/admin/cities/${cityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cities"],
    }),
    // Governments
    getGovernments: builder.query({
      query: () => "api/governments",
      providesTags: ["governments"],
    }),
    addGovernment: builder.mutation({
      query: (formData) => ({
        url: "api/admin/governments",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["governments"],
    }),
    deleteGovernment: builder.mutation({
      query: (governmentId) => ({
        url: `api/admin/governments/${governmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["governments"],
    }),
  }),
});

export const {
  // Auth & User Management
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetLogedUserQuery,
  useUpdateUserDataMutation,
  useCreateNewUserMutation,
  useAdminGetAllUserQuery,
  useDeleteUserMutation,

  // Categories
  useGetAllCategoriesQuery,
  useGetCategoryDetailsQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetFullCategoryDetailsQuery,
  useEditCategoryMutation,

  // Services
  useGetAllServicesQuery,
  useGetServiceDetailsQuery,
  useAddServiceMutation,
  useDeleteServiceMutation,
  useGetFullServiceDetailsQuery,
  useEditServiceMutation,

  // Products
  useGetAllProductsQuery,
  useGetAllProductsByCategoryQuery,
  useGetAllProductsPerPageQuery,
  useGetOneProductQuery,
  useGetAllSpecialProductsQuery,
  useAddProductMutation,
  useDeleteProductMutation,
  useGetFullProductDetailsQuery,
  useEditProductMutation,
  useDeleteProdPhotoMutation,
  useAddProdPhotoMutation,
  useMakeItemSpecialOrNotMutation,

  // Cities
  useGetCitiesQuery,
  useAddCityMutation,
  useDeleteCityMutation,
  // Governments
  useGetGovernmentsQuery,
  useAddGovernmentMutation,
  useDeleteGovernmentMutation,
} = api;
