import Heading from "../../../Components/Heading/Heading";
import ModalCustomer from "./Modal/ModalCustomer";
import TableCustomer from "./TableCustomer";

function Customer() {
  return (
    <>
      <Heading heading="العملاء" />
      <ModalCustomer />
      <TableCustomer />
    </>
  );
}

export default Customer;
