import Heading from "../../../Components/Heading/Heading";
import TableBillingReport from "./TableBillingReport";

function BillingReport() {
  return (
    <>
      <Heading heading="كل الفواتير" />
      <TableBillingReport />
    </>
  );
}

export default BillingReport;
