import {
  Button,
  Form,
  Input,
  InputRef,
  Select,
  Space,
  Table,
  TableColumnsType,
  TableColumnType,
  TableProps,
} from "antd";
import { useRef, useState } from "react";
import { DataIndex, DataType } from "./Type";
import { FilterDropdownProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const data: DataType[] = [
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "230",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "2",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "100",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "3",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "100",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "4",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "150",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "5",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "6",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "7",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "8",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "9",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "10",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
];

function TableServiceProviderStatement() {
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  // start Select Search
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  // end Select Search

  // serch start
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // serch end

  const columns: TableColumnsType<DataType> = [
    {
      title: "م",
      dataIndex: "Number",
      key: "Number",
      filteredValue: filteredInfo.Number || null,
      sorter: (a, b) => a.Number - b.Number,
      sortOrder: sortedInfo.columnKey === "Number" ? sortedInfo.order : null,
      ellipsis: true,
      width: 40,
    },
    {
      title: "اسم",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      ellipsis: true,
      width: 100,
      ...getColumnSearchProps("InvoiceDate"),
      filteredValue: filteredInfo.InvoiceDate || null,
    },
    {
      title: "الحاله",
      dataIndex: "AgentCode",
      key: "AgentCode",
      ellipsis: true,
      width: 120,
      ...getColumnSearchProps("AgentCode"),
      filteredValue: filteredInfo.AgentCode || null,
    },
    {
      title: "العنوان",
      dataIndex: "CustomerName",
      key: "CustomerName",
      ellipsis: true,
      width: 120,
    },
    {
      title: "رقم الموبيل",
      dataIndex: "Project",
      key: "Project",
      width: 100,
    },
    {
      title: "تاريخ الخدمه",
      dataIndex: "Project",
      key: "Project",
      width: 100,
    },
    {
      title: "قيمه الخدمه",
      dataIndex: "Project",
      key: "Project",
      width: 100,
    },
    {
      title: "",
      dataIndex: "Events",
      key: "Events",
      render: (_, record) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button type="primary">تعديل</Button>
          <Button type="primary" danger>
            حذف
          </Button>
        </div>
      ),
      width: 130,
    },
  ];
  return (
    <>
      <div className="">
        <Form.Item
          label="اسم مزود الخدمه"
          className="w-[300px] max-w-full mx-auto"
        >
          <Select
            showSearch
            placeholder="اسم مزود الخدمه"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
      </div>

      <Table
        columns={columns}
        bordered
        dataSource={data}
        onChange={handleChange}
        scroll={{ x: 1200, y: 350 }}
      />
    </>
  );
}

export default TableServiceProviderStatement;
