import TableCancelledInvoices from "./TableCancelledInvoices";
import Heading from "../../../Components/Heading/Heading";

function CancelledInvoices() {
  return (
    <>
      <Heading heading="الفواتير الملغاه" />
      <TableCancelledInvoices />
    </>
  );
}

export default CancelledInvoices;
