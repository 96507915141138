import Heading from "../../../Components/Heading/Heading";
import ModalServiceProviders from "./Modal/ModalServiceProviders";
import TableServiceProviders from "./TableServiceProviders";

function ServiceProviders() {
  return (
    <>
      <Heading heading="مزودى الخدمه" />
      <ModalServiceProviders />
      <TableServiceProviders />
    </>
  );
}

export default ServiceProviders;
