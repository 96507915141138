import Heading from "../../../Components/Heading/Heading";
import AddService from "./AddService";
import TableServiceSettings from "./TableServiceSettings";

function ServiceSettings() {
  return (
    <>
      <Heading heading="الخدمات" />
      <AddService />
      <TableServiceSettings />
    </>
  );
}

export default ServiceSettings;
