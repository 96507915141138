import { Button, Modal } from "antd";
import React, { useState } from "react";
import AddCustomer from "./AddCustomer";

function ModalCustomer() {
  const [openModalCustomerCodes, setOpenModalCustomerCodes] = useState(false);

  return (
    <>
      <Button
        className="w-[150px]"
        type="primary"
        onClick={() => setOpenModalCustomerCodes(true)}
      >
        اضافه
      </Button>
      <Modal
        title="اضافه اكود العملاء"
        style={{ top: 20 }}
        open={openModalCustomerCodes}
        onOk={() => setOpenModalCustomerCodes(false)}
        onCancel={() => setOpenModalCustomerCodes(false)}
        width={1200}
        footer={false}
      >
        <AddCustomer />
      </Modal>
    </>
  );
}

export default ModalCustomer;
