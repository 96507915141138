import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Card, Col, Input, Modal, Row, Upload, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import Heading from "../../../Components/Heading/Heading";
// import Heading from "../../Components/Heading/Heading";

const { Meta } = Card;

function SliderSettings() {
  const [openSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddProduct = async () => {
    if (!file) {
      message.error("يرجى تحميل ملف");
      return;
    }
    if (!title) {
      message.error("الرجاء إدخال عنوان");
      return;
    }

    // المعالجة المفترضة لرفع الملف
    try {
      // قم برفع الملف هنا
      setFile(null);
      setTitle("");
      setModalKey((prev) => prev + 1);
      message.success("تمت إضافة شريط التمرير بنجاح");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة شريط التمرير:", error);
      message.error(
        "حدث خطأ أثناء إضافة شريط التمرير. يرجى المحاولة مرة أخرى."
      );
    }
  };

  const handleDeleteSlider = async (id: number) => {
    // هنا منطق حذف العنصر
    message.success("تم حذف شريط التمرير بنجاح");
  };

  return (
    <div className="p-4">
      <Heading heading="السليدر" />

      <div className="flex justify-center mb-5">
        <Button type="primary" onClick={() => setOpenSliderModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          إضافة
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-5">
        {/* افتراضاً لو كان هناك تحميل */}
        {/* إضافة الكروت هنا */}
        <Card
          hoverable
          // className="w-1/4"
          cover={
            <img
              alt="slide-icon"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <DeleteFilled
              key="delete"
              className="!text-xl"
              onClick={() => handleDeleteSlider(1)} // استبدل "1" بالمعرف الفعلي
            />,
          ]}
        >
          <Meta title="Slide Title" />
        </Card>
        <Card
          hoverable
          // className="w-1/4"
          cover={
            <img
              alt="slide-icon"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <DeleteFilled
              key="delete"
              className="!text-xl"
              onClick={() => handleDeleteSlider(1)} // استبدل "1" بالمعرف الفعلي
            />,
          ]}
        >
          <Meta title="Slide Title" />
        </Card>
        <Card
          hoverable
          // className="w-1/4"
          cover={
            <img
              alt="slide-icon"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <DeleteFilled
              key="delete"
              className="!text-xl"
              onClick={() => handleDeleteSlider(1)} // استبدل "1" بالمعرف الفعلي
            />,
          ]}
        >
          <Meta title="Slide Title" />
        </Card>
        <Card
          hoverable
          // className="w-1/4"
          cover={
            <img
              alt="slide-icon"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <DeleteFilled
              key="delete"
              className="!text-xl"
              onClick={() => handleDeleteSlider(1)} // استبدل "1" بالمعرف الفعلي
            />,
          ]}
        >
          <Meta title="Slide Title" />
        </Card>
        <Card
          hoverable
          // className="w-1/4"
          cover={
            <img
              alt="slide-icon"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <DeleteFilled
              key="delete"
              className="!text-xl"
              onClick={() => handleDeleteSlider(1)} // استبدل "1" بالمعرف الفعلي
            />,
          ]}
        >
          <Meta title="Slide Title" />
        </Card>
      </div>

      {/* إضافة شريط التمرير */}
      <Modal
        title="إضافة"
        centered
        open={openSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        width={1000}
        footer={null}
        key={modalKey}
      >
        <div className="flex flex-wrap justify-between gap-5">
          <div className="flex flex-col w-full md:w-1/2">
            <Input
              size="large"
              placeholder="عنوان"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mb-4"
            />
          </div>
          <div className="w-full">
            <Upload
              customRequest={({ file, onSuccess }) => {
                onSuccess?.("ok");
              }}
              onChange={({ fileList }) => handleFileChange(fileList)}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<FontAwesomeIcon icon={faDownload} />}>
                تحميل (الحد الأقصى: 1)
              </Button>
            </Upload>
          </div>
          <div className="mt-4">
            <Button type="primary" onClick={handleAddProduct} loading={false}>
              إضافة
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SliderSettings;
