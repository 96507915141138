import { Link } from 'react-router-dom';
import Gallary from '../../assets/Png/catogery.png'
import Gallary1 from '../../assets/Png/catogery1.png';
import Gallary2 from '../../assets/Png/catogery2.png';
const OurservicesGallary = () => {
	return (
		<div className='mt-10 flex flex-col h-full gap-8 mid:gap-5 container mb-20'>
			<div className={`w-[100%]  flex justify-between  miilg:gap-8 mid:gap-5`}>
				<Link to={"/aboutUs"} className=' w-[30%] h-[600px] mid:h-[400px] midd:h-[250px] miilg:w-full'>
					<img src={Gallary} className='w-full h-full' alt="" />
				</Link>
				<Link to={"/aboutUs"} className='w-[67%] h-[600px] mid:h-[400px] midd:h-[250px] miilg:w-full rounded-br-[130px] mid:rounded-br-[80px]'>
					<img src={Gallary1} className='w-full h-full rounded-br-[130px] mid:rounded-br-[80px]' alt="" />
				</Link>
			</div>
			<Link to={"/aboutUs"} className='w-[100%] h-[500px] mid:h-[300px] midd:h-[170px] mid:rounded-bl-[80px]'>
				<img className='w-[100%] h-full  rounded-bl-[130px] mid:rounded-bl-[80px]' src={Gallary2} alt="" />
			</Link>
		</div>
	)
}

export default OurservicesGallary