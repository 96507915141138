import PlatformNews1 from "../../assets/PlatformNews.svg";
function PlatformNews() {
  return (
    <div className="container mt-7 md:mt-14">
      <h1 className="text-xl md:text-3xl font-bold mb-4">اخبار لمنصه </h1>
      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 bg-[#F3EDF7] rounded-bl-3xl">
          <div className="">
            <img src={PlatformNews1} alt="PlatformNews" />
          </div>
          <div className="md:col-span-2 relative">
            <h3 className="text-xl md:text-2xl xl:text-4xl font-semibold text-[#180337] tracking-wide pb-8 sm:pt-5 sm:pb-11 px-2">
              فى اطار التعاون المشترك مع وزاره السياحه تم توقيع اتفاقيه تقديم
              الخدمات لزوار المملكه خلال موسم الرياض
            </h3>
            <button
              type="button"
              className="absolute left-0 bottom-0 rounded-bl-3xl focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 text-sm
              md:text-lg font-semibold px-11 py-1.5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
            >
              المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlatformNews;
