import { Link } from 'react-router-dom';
import './ourservices.scss';
import OurservicesGallary from './OurservicesGallary';

const Ourservices = () => {
	return (
		<div className={`bg-[#fff] Ourservices pb-20`}>
			<h2 className='flex items-center flex-col gap-2 mt-8 font-bold text-[35px] miilg:text-[20px] mid:text-[18px]'>
				خدماتك  ومناسباتك
				فى مكان واحد
			</h2>
			<OurservicesGallary />
			<div className={`text-center `}>
				<Link to={`/aboutUs`} className={`pr-20 pl-20 pt-[4px] pb-[4px] bg-[#466FFF] text-[#fff] text-[17px] font-bold`}> التالي</Link>
			</div>
		</div>
	)
}

export default Ourservices