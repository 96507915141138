import { Button, Drawer, Input } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';


const AddEmailProfile = () => {
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};



	const onClose = () => {
		setOpen(false);
	};
	return (
		<>
			<div className='bg-[#ffff] mb-3 pt-7 pb-7'>
				<div className='container flex justify-between'>
					<div className='w-[80%]'>
						<Button onClick={showDrawer} className='bg-[#282828] text-[#ffff] pr-5 pl-5text-[12px]'>
							حفظ
						</Button>
						<Drawer
							// title="Drawer with extra actions"
							placement={"bottom"}
							width={500}
							onClose={onClose}
							closeIcon={false}
							open={open}
							extra={
								<></>
							}
						>
							<div className='flex flex-col gap-[30px] items-center'>
								<div className='bg-[#3FAD46] w-[80px] rounded-[50%] h-[80px] flex justify-center items-center'>
									<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.2" d="M39.376 9.84375L22.501 25.3125L5.62598 9.84375H39.376Z" fill="white" />
										<path d="M39.3745 9.84375L22.4995 25.3125L5.62451 9.84375" stroke="white" stroke-width="2.1875" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M5.62451 9.84375H39.3745V33.75C39.3745 34.123 39.2264 34.4806 38.9626 34.7444C38.6989 35.0081 38.3412 35.1562 37.9683 35.1562H7.03076C6.6578 35.1562 6.30012 35.0081 6.03639 34.7444C5.77267 34.4806 5.62451 34.123 5.62451 33.75V9.84375Z" stroke="white" stroke-width="2.1875" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M19.4318 22.5L6.05859 34.7588" stroke="white" stroke-width="2.1875" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M38.9418 34.7589L25.5684 22.5" stroke="white" stroke-width="2.1875" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</div>
								<h2 className=' font-bold text-[20px] miilg:text-[20px] mid:text-[18px]'>
									تفعيل الإيميل
								</h2>
								<p className='text-[#6F7485] text-[13px]'>
									يرجى التحقق من صندوق بريدك
									سيتم ارسال رساله يرجى الظغط على تفعيل الحساب
								</p>
								<Button onClick={onClose} className='bg-[#282828] text-[#ffff] pb-3 pt-3 text-[12px] font-bold w-[20%]'>
									ok
								</Button>
							</div>
						</Drawer>
					</div>
					<div className='flex justify-center gap-[13px] items-center w-[200px] miilg:w-[300px] miilg:justify-end'>
						<h2 className=' font-bold text-[20px]  miilg:text-[20px] mid:text-[18px]'>
							عدل عنوان بريدك
						</h2>
						<Link to={'/profile'} className='flex mt-[5px] items-center justify-center h-fit'>
							<LeftOutlined
							/>
						</Link>
					</div>
				</div>

			</div>
			<div className='container'>
				<Input placeholder="Basic usage" value={"saddamcsit@gmail.com"} className='h-[40px]' />

			</div>
		</>
	)
}

export default AddEmailProfile