import React, { useState } from 'react';
import { Button, Drawer, Radio, Space } from 'antd';
import type { DrawerProps, RadioChangeEvent } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
const WorkerLoginDrawer: React.FC = () => {
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button type="primary" className='bg-[#090357] w-[100%]' onClick={showDrawer}>
				نأكيد
			</Button>
			<Drawer
				className='workersubmit'
				// title="Drawer with extra actions"
				placement={"bottom"}
				width={500}
				onClose={onClose}
				open={open}
				extra={
					<Space>
						{/* <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              OK
            </Button> */}
					</Space>
				}
			>
				<div className='flex justify-center items-center flex-col gap-[20px]'>
					<div className='w-[70px] h-[70px] bg-[#0FCC5D] rounded-[50%] flex justify-center items-center text-[#ffff]'>
					<CheckCircleOutlined className='text-[40px]'/>
					</div>
					<h2 className='text-[15px]'>
						تم التسجيل بنجاح
					</h2>
					<p className='text-[#6F7485] text-[13px]'>
						عداء بيك
						سيتم التواصل من قبل ممثلى خدمه العملاء للتأكيد
					</p>
					<Link to={'/'} className='w-[200px] bg-[#282828] text-[#ffff] pt-3 pb-3 rounded-[5px] text-center'>
						Ok
					</Link>
				</div>
			</Drawer>
		</>
	);
};

export default WorkerLoginDrawer;