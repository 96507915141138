import Heading from "../../../Components/Heading/Heading";
import ModalComplaintsAndSuggestions from "./Modal/ModalComplaintsAndSuggestions";
import TableComplaintsAndSuggestions from "./TableComplaintsAndSuggestions";

function ComplaintsAndSuggestions() {
  return (
    <>
      <Heading heading="الشكاوى والاقترحات" />
      <ModalComplaintsAndSuggestions />
      <TableComplaintsAndSuggestions />
    </>
  );
}

export default ComplaintsAndSuggestions;
