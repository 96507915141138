import React from "react";
import TableExecutedInvoices from "./TableExecutedInvoices";
import Heading from "../../../Components/Heading/Heading";

function ExecutedInvoices() {
  return (
    <>
      <Heading heading="الفواتير المنفذه" />
      <TableExecutedInvoices />
    </>
  );
}

export default ExecutedInvoices;
