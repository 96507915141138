import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useLoginMutation } from "../../redux/Service/api";
import { useDispatch } from "react-redux";
import { reduxLogin } from "../../redux/Slices/authSlice";

type FieldType = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loginUser, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const onFinish = async (values: FieldType) => {
    try {
      const response = await loginUser(values).unwrap();
      const token = response?.data?.token;

      if (token) {
        dispatch(reduxLogin());
        window.localStorage.setItem("hejozatiToken", token);
        navigate("/");
      } else {
        throw new Error("فشل تسجيل الدخول: رمز غير صالح");
      }
    } catch (err) {
      console.error("خطأ في تسجيل الدخول:", err);
      form.setFields([
        {
          name: "email",
          errors: ["البريد الإلكتروني أو كلمة المرور غير صحيحة."],
        },
        {
          name: "password",
          errors: [""],
        },
      ]);
    }
  };

  return (
    <div className="container my-6 flex flex-col items-center justify-center">
      <div className="mb-6">
        <h3 className="text-[#133A9F] md:text-3xl text-2xl font-extrabold max-md:text-center">
          تسجيل دخول
        </h3>
      </div>
      <div className="grid items-center gap-2 max-md:gap-8 max-w-4xl max-md:max-w-lg w-full p-4 m-1 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md">
        <Form
          form={form}
          className="w-full"
          name="loginForm"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="البريد الإلكتروني"
            name="email"
            rules={[
              { required: true, message: "يرجى إدخال البريد الإلكتروني!" },
              { type: "email", message: "يرجى إدخال بريد إلكتروني صالح!" },
            ]}
          >
            <Input
              placeholder="أدخل بريدك الإلكتروني"
              prefix={<MailOutlined />}
              className="w-full text-sm rounded-[10px] px-2 py-2"
            />
          </Form.Item>

          <Form.Item
            label="كلمة المرور"
            name="password"
            rules={[
              { required: true, message: "يرجى إدخال كلمة المرور!" },
              {
                min: 6,
                message: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل!",
              },
            ]}
          >
            <Input.Password
              placeholder="أدخل كلمة المرور"
              prefix={<LockOutlined />}
              className="w-full text-sm rounded-[10px] px-2 py-2"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>تذكرني</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              تسجيل الدخول
            </Button>
          </Form.Item>

          <div>
            <p className="flex items-center gap-1">
              <span className="text-[#888888] text-[13px]">ليس لديك حساب؟</span>
              <Link to="/signUp" className="text-[#EA9459] text-[12px]">
                انضم إلينا
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
