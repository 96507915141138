import { Button, Input, message, Modal, Upload } from "antd";
import Hero1 from "../../../assets/Hero1.svg";
import Hero2 from "../../../assets/Hero2.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function ArticleSettings() {
  const [openSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddProduct = async () => {
    if (!file) {
      message.error("يرجى تحميل ملف");
      return;
    }
    if (!title) {
      message.error("الرجاء إدخال عنوان");
      return;
    }

    // المعالجة المفترضة لرفع الملف
    try {
      // قم برفع الملف هنا
      setFile(null);
      setTitle("");
      setModalKey((prev) => prev + 1);
      message.success("تمت إضافة شريط التمرير بنجاح");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة شريط التمرير:", error);
      message.error(
        "حدث خطأ أثناء إضافة شريط التمرير. يرجى المحاولة مرة أخرى."
      );
    }
  };

  return (
    <div className="">
      <div className="container grid grid-cols-1 gap-5 md:gap-10">
        <div className="flex justify-center ">
          <Button
            onClick={() => setOpenSliderModal(true)}
            type="primary"
            className="md:w-28"
          >
            اضافه
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5 items-center border-[3px] px-5 py-10 rounded-b-3xl shadow-lg">
          <div className="md:col-span-3 flex justify-center gap-3">
            <Button type="primary" className="md:w-28">
              تعديل
            </Button>
            <Button type="primary" className="md:w-28" danger>
              حذف
            </Button>
          </div>
          <img
            src={Hero1}
            alt="hero"
            className="rounded-full md:rounded-none w-[150px] md:w-auto"
          />
          <h2 className="md:col-span-2 text-2xl md:text-4xl font-bold text-[#180337] md:leading-[3rem]">
            مرحبا بكم فى منصه حجوزاتى لتقديم الخدمات منصه حجوزاتى هى المنصه
            الاولى فى المملكه تجمع كل المناسبات واجتماعات الاصدقاء والشله فى
            مكان واحد
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5 items-center border-[3px] px-5 py-10 rounded-b-3xl shadow-lg">
          <div className="md:col-span-3 flex justify-center gap-3">
            <Button type="primary" className="md:w-28">
              تعديل
            </Button>
            <Button type="primary" className="md:w-28" danger>
              حذف
            </Button>
          </div>
          <img
            src={Hero2}
            alt="hero"
            className="rounded-full md:rounded-none w-[150px] md:w-auto"
          />
          <h2 className="md:col-span-2 text-2xl md:text-4xl font-bold text-[#180337] md:leading-[3rem]">
            بعيونا راح تراها أجمل
            <div className="mt-5 md:mt-10"></div>
            ريح بالك ولا تحتار وفرنا لك قائمه متنوعه من الخدمات ومزودى الخدمه
            لاتشيل هم
          </h2>
        </div>

        {/* إضافة مقالات */}
        <Modal
          title="إضافة"
          centered
          open={openSliderModal}
          onCancel={() => setOpenSliderModal(false)}
          width={1000}
          footer={null}
          key={modalKey}
        >
          <div className="flex flex-wrap justify-between gap-5">
            <div className="flex flex-col w-full">
              <Input
                size="large"
                placeholder="عنوان"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mb-4"
              />
            </div>
            <div className="w-full">
              <Upload
                customRequest={({ file, onSuccess }) => {
                  onSuccess?.("ok");
                }}
                onChange={({ fileList }) => handleFileChange(fileList)}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<FontAwesomeIcon icon={faDownload} />}>
                  تحميل (الحد الأقصى: 1)
                </Button>
              </Upload>
            </div>
            <div className="mt-4">
              <Button type="primary" onClick={handleAddProduct} loading={false}>
                إضافة
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ArticleSettings;
