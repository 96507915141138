import { RightOutlined, StarFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import catogery1 from '../../assets/Png/catogery.png'
import catogery2 from '../../assets/Png/catogery1.png'
import catogery3 from '../../assets/Png/catogery2.png'
import catogery4 from '../../assets/Png/catogery3.jpeg'
import { Button } from 'antd';


const OrderDetails = () => {
	const navigate = useNavigate();

	return (
		<div className="container pb-12 pt-5">
			<h2 className='flex mb-5 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>
				<RightOutlined className='text-[18px] cursor-pointer' onClick={() => navigate("/companyCatogery")} />
				<span >
					مراجعه  الطلب
				</span>
			</h2>
			<div className='flex justify-center gap-[15px] miilg:flex-col'>
				<div className='bg-[#E2E8FF] p-5 flex flex-col gap-[80px] w-[50%] miilg:w-[100%]'>
					<div className='flex justify-between  items-center'>
						<p className='text-[15px] text-[#545454]'>
							العنوان:
						</p>
						<p className='text-[11px] font-bold'>
							ش حسان بن ثابت الرياض
						</p>
					</div>
					<div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								تاريخ الطلب
							</p>
							<p className='text-[12px]'>
								18/8/2024
							</p>
						</div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								تاريخ الخدمه
							</p>
							<p className='text-[12px]'>
								26/8/2024
							</p>
						</div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								ملاحظات
							</p>
							<p className='text-[12px]'>
								لايوجد ملاحظات
							</p>
						</div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								عدد الافراد
							</p>
							<p className='text-[12px]'>
								15
							</p>
						</div>
					</div>
				</div>
				<div className='bg-[#E2E8FF] p-5 flex flex-col gap-[80px] w-[50%] miilg:w-[100%]'>
					<div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								نوع الخدمه
							</p>
							<p className='text-[12px] text-[#0B107A] font-bold'>
								كشته بريه
							</p>
						</div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454] '>
								اسم مزود الخدمه
							</p>
							<p className='text-[12px] text-[#0B107A] '>
								محمد الحمودى للمناسبات
							</p>
						</div>
						<div className='flex justify-between w-[95%] items-center mb-5'>
							<p className='text-[13px] text-[#545454]'>
								إجمالى السعر
							</p>
							<p className='text-[12px]'>
								700 ريال سعودى
							</p>
						</div>
						<div className='flex flex-col justify-between w-[95%] items-start mb-5'>
							<p className='text-[13px] mb-[20px] text-[#545454]'>
								الخدمات المختاره
							</p>
							<div className='flex justify-between items-center gap-[20px] gap-y-[50px] miilg:w-[100%] miilg:flex-wrap'>
								<div className='w-[85px] h-[85px]'>
									<img src={catogery1} className='rounded-[50%] h-full w-full object-cover' alt="" />
									<h3 className='text-[10px] text-center mt-[10px] text-[#090357]'>
									معد 
									قهوة
									</h3>
								</div>
								<div className='w-[85px] h-[85px]'>
									<img src={catogery2} className='rounded-[50%] h-full w-full object-cover' alt="" />
									<h3 className='text-[10px] text-center mt-[10px] text-[#090357]'>
									تجهيز 
									خيام
									</h3>
								</div>
								<div className='w-[85px] h-[85px]'>
									<img src={catogery3} className='rounded-[50%] h-full w-full object-cover' alt="" />
									<h3 className='text-[10px] text-center mt-[10px] text-[#090357]'>
									حفله
									شواء
									</h3>
								</div>
								<div className='w-[85px] h-[85px]'>
									<img src={catogery4} className='rounded-[50%] h-full w-full object-cover' alt="" />
									<h3 className='text-[10px] text-center mt-[10px] text-[#090357]'>
									ضيـافة وقـهـوة
									</h3>
								</div>
							</div>

						</div>
						
					</div>
					
				</div>
				
			</div>
			{/* <div className='text-center mt-7'>
								<Button  className='bg-[#0B247C] text-[#ffff] pr-5 pl-5text-[12px]'>
								اذهب للدفع
						</Button>
							</div> */}
		</div>
	)
}

export default OrderDetails